'use client';

import { DefaultThemeRoot } from '@ensite/ui/lib/themes';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Inter } from 'next/font/google';
import ErrorLayout from './error-layout';

const inter = Inter({
  variable: '--font-family',
  display: 'swap',
  preload: true,
  adjustFontFallback: true,
  subsets: ['latin'],
  weight: ['400', '500', '700'],
});

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
  return (
    <ErrorLayout className={inter.variable}>
      <DefaultThemeRoot>
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap={2}
        >
          <HeartBrokenIcon sx={{ fontSize: '32px' }} />
          <Typography>Client-side error: {error.message}</Typography>
          <Button variant="outlined" onClick={() => reset()}>
            Try again
          </Button>
        </Box>
      </DefaultThemeRoot>
    </ErrorLayout>
  );
}
